.select {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #333;
  color: grey;
  border-radius: 15px solid;
  border: none;
}

.option {
  padding: 0 30px 0 10px;
  min-height: 40px;
  display: flex;
  align-items: center;
  background: #333;
  border-top: #222 solid 1px;
  position: absolute;
  top: 0;
  width: 100%;
  pointer-events: none;
  order: 2;
  z-index: 1;
  transition: background 0.4s ease-in-out;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
}

.option:hover {
  background-color: white;
}
