.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.card-classic {
  width: 18rem;
  min-height: 500px;
}
.card-img {
  object-fit: contain;
}
@media (max-height: 801px) {
  .card-classic {
    height: 450px;
  }
}
