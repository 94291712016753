@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Roboto:wght@300&display=swap");
.about-container {
  background: url("../images/build.jpg") no-repeat;
  background-size: 100%;
  height: 100%;
  overflow: hidden;
  padding: 100px 0;
}
.inner-container {
  width: 55%;
  float: right;
  background-color: #fdfdfdbb;
  padding: 250px;
}
.inner-container h1 {
  font-family: "Playfair Display", serif;
  font-weight: 900;
  letter-spacing: 2px;
  margin-bottom: 30px;
  font-size: 40px;
  font-weight: 900;
  color: #4f75a1;
}
.text {
  font-family: "Raleway", sans-serif;
  font-size: 17px;
  color: #3d3d3d;
  line-height: 30px;
  text-align: justify;
  margin-bottom: 40px;
  font-weight: 200;
}

@media screen and (max-width: 1410px) {
  .about-container {
    background-size: cover;
  }

}

@media screen and (max-width: 1250px) {
  .about-container {
    background-size: cover;
    /*padding: 100px 40px;*/
  }
  .inner-container {
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .about-container {
    /* padding: 100px 40px;*/
    background-size: cover;
  }
  .inner-container {
    background-color: #fdfdfd50;
    padding: 60px;
    text-align: center;
    height: 100%;
  }
  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .about-container {
    padding: 0;
    background: url("../images/build.jpg") no-repeat;
    background-size: cover;
  }
  .inner-container {
    background-color: #fdfdfd50;
    padding: 60px;
    text-align: center;
  }
  .text {
    font-size: 14px;
    color: #020202;
  }
}
@media screen and (max-height: 800px) {
  .about-container {
    padding: 0;
    background: url("../images/build.jpg") no-repeat;
    background-size: cover;
  }
  .inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fdfdfd50;
    padding: 60px;
    text-align: center;
    height: 100%;
  }
}
