@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Roboto:wght@300&display=swap");
.gallery-container {
  text-align: center;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.img-shower {
  width: 70%;
  align-self: center;
}
.carousel {
  width: 100%;
  height: 100%;
}
.gallery-container .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%234f75a1' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
.gallery-container .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%234f75a1' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}
.gallery-container h1 {
  color: #4f75a1;
  letter-spacing: 5px;
  font-weight: 900;
  font-size: 72px;
  font-family: "Playfair Display", serif;
}
@media (max-width: 800px) {
  .gallery-container h1 {
    margin-top: 5%;
    font-size: 22px;
  }
}
