@media (max-width: 992px) {
  .view {
    text-align: center;
    justify-content: center;
  }
  .selector {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.view {
  justify-content: flex-end;
}
.navbr {
  background-color: transparent;
}
.navbar-bg {
  background-color: rgb(0, 0, 0);
}
