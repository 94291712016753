@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Roboto:wght@300&display=swap");

.hero-div {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.hero-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.brand-title {
  letter-spacing: 8px;
  font-family: "Playfair Display", serif;
  color: #fdfffc;
}
.brand-slogan {
  color: #ee0e0e;
  font-family: "Roboto", sans-serif;
  font-style: italic;
}
