@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@1,700&display=swap");
.modal-content {
  background-color: #ffffff;
  padding: 0;
}
.details {
  font-family: "Raleway", sans-serif;
  color: rgb(0, 0, 0);
  font-weight: 100;
}
.modal-header {
  padding: 0;
}

.modal-footer {
  border-top: 0px solid;
}
.title {
  font-weight: bold;
}
