body {
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
section {
  width: 100%;
}
.hero-section {
  width: 100%;
  height: 100vh;
}
.about-section {
  width: 100%;
  height: 100vh;
}
.product-section {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-section {
  width: 100%;
}

.gallery-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
}

@media (max-width: 700px) {
  .product-section {
    height: 100vh;
  }
}
@media (max-height: 900px) {
  .product-section {
    height: 130vh;
  }
}
